import React, { Fragment, useEffect, useState } from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Label from "@ui/components/Label";
import { Grid } from "@material-ui/core/index";
import priceBreakUpIcon from "@ui/assets/e-creatives/orders/priceBreakup.svg";
import {
  formatDateToRead,
  formatWeight,
  getNestedObjValue,
  getProductImageUri,
  imageExpired,
  isAdmin,
  isDivisionControlled,
  isVendor,
  oopsNotFound,
  roundOff,
  selectedDivisionId,
  selectedDivisionName,
} from "@ui/Utils/helper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/index";
import { defaultFilter } from "../Detail";
import { getSingleRecord, search } from "@ui/ComponentUtils/blueprintAPIs";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { Typography } from "@material-ui/core/index";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import commonConfig from "config/commonConfig";
import { Dialog } from "@material-ui/core/index";
import {
  MyCarousel,
  MyDialogContent,
  MyDialogTitle,
} from "@ui/MuiComponents/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@material-ui/core/index";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import "./divisionAnimation.css";
import LazyLoad from "@ui/Actions/LazyLoad/index";
import { getAvailableProductsQuantity } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import generatePDF from "@ui/Utils/PDF/generate_pdf";

// const columns = [
//   { label: "Product", field: "product.styleNo" },
//   { label: "Category", field: "product.category.name" },
//   { label: "Image", field: "product.image" },
//   { label: "Melting", field: "melting" },
//   { label: "Screw", field: "screw" },
//   { label: "Size", field: "size" },
//   { label: "Color", field: "color" },
//   { label: "Remarks", field: "remarks" },
//   { label: "Quantity", field: "quantity" },
// ];

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch } = formMethods;
  const values = watch();
  const customerId = values.createdBy?._id;
  const model = enums.models["e-creatives"]?.orders;
  const params = useParams();
  const navigate = useNavigate();
  const [vendorCodes, setVendorCodes] = useState([]);
  const [setting, setSetting] = useState({});
  const [stockAvailable, setStockAvailable] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models["vendor-portal"]?.vendorCodes)) || [];
        setVendorCodes(res?.[0]?.availableCodes || []);
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        setSetting(
          (await getSingleRecord(enums.models["vendor-portal"]?.settings)) || {}
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (values.code) {
          const styleNos = (values.items || []).map(
            (item) => item?.product?.styleNo
          );

          const res =
            (await getAvailableProductsQuantity({
              styleNo: { $in: styleNos },
            })) || "-";

          const obj = {};
          res.map((prod) => (obj[prod.styleNo] = prod.count));
          setStockAvailable(obj);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [values.code]);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [
    {
      name: enums.ordersType.customerOrder,
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: `${values.orderId || ""}`,
      url: "",
      isDisplayLink: false,
    },
  ];

  const downloadPdf = async () => {
    try {
      setLoading(true);
      const tempSetting = { ...setting };

      setSetting(tempSetting);
      if (!tempSetting?._id || !tempSetting?.pdfTemplate) {
        return Emitter.emit(
          "alert_error",
          "Configure PDF Settings to continue"
        );
      }

      const filteredItems = values.items?.filter((item) => {
        return defaultFilter(item);
      });

      let totalGrossWeight = 0,
        totalNetWeight = 0,
        totalQuantity = 0;

      const pdfProducts = filteredItems.map((item) => {
        item = item || {};
        item.product = { ...item.product };

        totalGrossWeight +=
          (item.product?.grossWeight || item.grossWeight || 0) * item.quantity;
        totalNetWeight +=
          (item.product?.netWeight || item.netWeight || 0) * item.quantity;
        totalQuantity += item.quantity || 0;

        return {
          styleNo: item.product?.styleNo,
          grossWeight: Number(item.product?.grossWeight || 0).toFixed(3),
          netWeight: (Number(item.product?.netWeight) || 0).toFixed(3),
          purity: item.purity?.value
            ? item.purity?.value + " KT"
            : item.product?.purity?.value
            ? item.product?.purity?.value + " KT"
            : "",
          color: item.color || item.product?.color,

          adminRemarks: item?.remarks,
          dueDate: item?.dueDate ? formatDateToRead(item?.dueDate) : "",

          image: getProductImageUri(item.product || {}, true),
          quantity: item?.quantity,

          motiWeight: item?.product?.motiWeight,
          kundanWeight: item?.product?.kundunWeight,
        };
      });

      const orderDate = values.dateCreated
        ? formatDateToRead(values.dateCreated)
        : "";

      await generatePDF(
        {
          data: {
            vendorCode: "",
            remarks: values.overallRemarks,
            orderId: values.orderId,
            orderDate: orderDate,
            products: pdfProducts,

            totalGrossWeight: totalGrossWeight.toFixed(3),
            totalNetWeight: totalNetWeight.toFixed(3),
            totalQuantity: totalQuantity,
            customerName: isAdmin
              ? values.createdBy?.firstName + " " + values.createdBy?.lastName
              : "",
          },
          title: "E-Commerce",
          selectedTemplate: tempSetting.pdfTemplate,
          appModule: "vendor-portal",
          model: "quotations",
        },
        values.orderId
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const actions = [
    {
      label: "Download PDF",
      tooltip: setting.pdfTemplate
        ? "Download PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable,
      disabled: setting.pdfTemplate ? false : true,
      onClick: downloadPdf,
    },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden:
        isEditable ||
        !values.items?.filter((item) => {
          return defaultFilter(item);
        }).length,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  const headerValueStyle = {
    fontWeight: "bold",
  };

  return (
    <>
      {loading ? (
        <h2>
          <pre style={{ color: enums.colorTheme.primary }}>
            Downloading PDF: This will take a moment
          </pre>
        </h2>
      ) : (
        <Header links={links} pageTitle={"E-Commerce"} actions={actions} />
      )}

      <Grid container spacing={3} style={{ marginBottom: "24px" }}>
        <Grid item xs={3}>
          <Label
            label={"Order ID"}
            value={values.orderId}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Overall Remarks"}
            value={values.overallRemarks}
            valueStyle={headerValueStyle}
          />
        </Grid>

        {isVendor ? null : (
          <>
            <Grid item xs={3}>
              <Label
                label={"Order By"}
                value={`${
                  (values.createdBy?.firstName || "") +
                  " " +
                  (values.createdBy?.lastName || "")
                }`}
                valueStyle={headerValueStyle}
              />
            </Grid>

            <Grid item xs={3}>
              <Label
                label={"Contact"}
                value={values.createdBy?.phone || ""}
                valueStyle={headerValueStyle}
              />
            </Grid>
          </>
        )}
      </Grid>

      {(values.items?.filter((item) => defaultFilter(item)) || []).length ? (
        (values.items || []).map(
          (item, itemIndex) =>
            defaultFilter(item) && (
              <ProductItem
                key={itemIndex}
                item={item}
                itemIndex={itemIndex}
                isEditable={isEditable}
                customerId={customerId}
                vendorCodes={vendorCodes}
                initialValues={initialValues}
                stockAvailable={stockAvailable[item.product?.styleNo] || 0}
              />
            )
        )
      ) : values.code ? (
        <div style={{ textAlign: "center" }}>
          <div>
            <img
              src={oopsNotFound}
              style={{ height: "320px", width: "440px" }}
            />
          </div>
          <div>
            <h3>{`No products were ordered in ${selectedDivisionName} Division`}</h3>
          </div>
        </div>
      ) : null}
    </>
  );
};

const diamondColumns = [
  { label: "Sr No", field: "srNo" },
  { label: "Quantity", field: "diamondQuantity" },
  { label: "Total Carat (cts)", field: "totalCarat" },

  { label: "Shape", field: "shape" },
  { label: "Color", field: "color" },

  { label: "Solitaire Length (mm)", field: "solitaireSize.length" },
  { label: "Solitaire Width (mm)", field: "solitaireSize.width" },

  { label: "Sieve Length (mm)", field: "sieveSize.length" },
  { label: "Sieve Width (mm)", field: "sieveSize.width" },

  { label: "Cut", field: "cut" },
  { label: "Clarity", field: "clarity" },

  { label: "Polish", field: "polish" },
  { label: "Symmetry", field: "symmetry" },
  { label: "Fluorescence Intensity", field: "fluorescenceIntensity" },
  { label: "Grading Lab", field: "gradingLab" },
];

const ProductItem = ({
  item,
  itemIndex,
  isEditable,
  vendorCodes = [],
  customerId,
  initialValues,
  stockAvailable = 0,
}) => {
  const sameDivision = isDivisionControlled
    ? selectedDivisionId === item?.product?.division?._id || // remove this line after 1 PROD Migration for existing clients... #11th March, 2024
      selectedDivisionId === item?.division?._id
    : true;

  isEditable = isDivisionControlled
    ? sameDivision
      ? isEditable
      : false
    : isEditable;

  const formMethods = useFormContext();
  const { setValue } = formMethods;
  const productSearchUrl =
    enums.models.products + "/search?hook=true&priceAfterDiscount=true";
  const lockedFilterCriteria = {
    $or: [
      {
        "lockedFrom.orderPro": { $exists: false },
      },
      { "lockedFrom.orderPro": customerId },
    ],
  };
  // const [uiConsoleObject, setUiConsoleObject] = useState(null);

  const [openBreakup, setOpenBreakup] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [issuedStocksOptions, setIssuedStocksOptions] = useState([]);
  const isCustomized = item.isCustomized;
  const originalItem = initialValues?.items?.[itemIndex] || {};
  const isDelivered = item.orderStatus === enums.orderStatus.delivered,
    isRepair = item.orderStatus === enums.orderStatus.repair,
    isReturned = item.orderStatus === enums.orderStatus.returned;

  const isAvailable = stockAvailable >= item.quantity;
  const headingColor =
    isCustomized === enums.customizationType.product
      ? { backgroundColor: "black", color: "white" }
      : isCustomized === enums.customizationType.scratch
      ? { backgroundColor: "#a86d32", color: "white" }
      : { backgroundColor: "green", color: "white" };

  const [openImagePreview, setOpenImagePreview] = useState([]);
  const stockImageLocation =
    isCustomized === enums.customizationType.scratch
      ? item.images?.[0]?.signedUrl
      : getProductImageUri(item?.product || {});

  useEffect(() => {
    if (isReturned && !issuedStocksOptions.length) {
      setIssuedStocksOptions([
        ...(originalItem.issuedStocks || []),
        ...(originalItem.repairStocks || []),
      ]);
    }
  }, [isReturned, item.issuedStocks]);

  const getWorkflowOptions = () => {
    return Object.values(enums.orderStatus).filter((oStatus) => {
      if (oStatus === enums.orderStatus.repair)
        return (
          originalItem.orderStatus === enums.orderStatus.delivered ||
          originalItem.orderStatus === enums.orderStatus.repair ||
          originalItem.orderStatus === enums.orderStatus.returned
        );
      else if (oStatus === enums.orderStatus.returned)
        return (
          originalItem.orderStatus === enums.orderStatus.delivered ||
          originalItem.orderStatus === enums.orderStatus.repair ||
          originalItem.orderStatus === enums.orderStatus.returned
        );

      switch (originalItem?.orderStatus) {
        case enums.orderStatus.inReview:
          return true;
        case enums.orderStatus.accepted:
          return oStatus !== enums.orderStatus.inReview;

        case enums.orderStatus.making:
          return !(
            oStatus === enums.orderStatus.accepted ||
            oStatus === enums.orderStatus.inReview
          );
        case enums.orderStatus.dispatched:
          return !(
            oStatus === enums.orderStatus.making ||
            oStatus === enums.orderStatus.accepted ||
            oStatus === enums.orderStatus.inReview
          );
        case enums.orderStatus.delivered:
          return (
            oStatus === enums.orderStatus.repair ||
            oStatus === enums.orderStatus.delivered ||
            oStatus === enums.orderStatus.returned
          );
        case enums.orderStatus.repair:
          return (
            oStatus === enums.orderStatus.repair ||
            oStatus === enums.orderStatus.delivered ||
            oStatus === enums.orderStatus.returned
          );
        case enums.orderStatus.returned:
        case enums.orderStatus.canceled:
          return true;
      }
    });
  };
  return (
    <MainSection
      title={
        <Grid container spacing={2}>
          <Grid item xs={isCustomized ? 10 : 12}>
            <h3
              style={{
                ...headingColor,
                textAlign: "center",
                height: 50,
                paddingTop: 15,
              }}
            >
              {isCustomized
                ? `${
                    itemIndex + 1
                  }. Customized from ${isCustomized.toUpperCase()}`
                : `${itemIndex + 1}. ORIGINAL Product`}
            </h3>
          </Grid>
          {isCustomized ? (
            <Grid item xs={2}>
              <Tooltip title="View Customizations">
                <VisibilityIcon
                  onClick={() => setOpenDetails(true)}
                  style={{ fontSize: 50, cursor: "pointer", marginTop: "16px" }}
                />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      }
      titleStyle={{ color: "black" }}
      style={{ marginBottom: "24px", opacity: sameDivision ? 1 : 0.5 }}
      actions={[
        {
          buttonType: "custom",
          customIcon: (
            <div
              style={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                padding: "8px 12px",
                borderRadius: "12px",
                backgroundColor: sameDivision ? "black" : "grey",
                color: sameDivision ? "gold" : "white",
                fontSize: "14px",
                fontWeight: "bold",
                overflow: "hidden", // Hide overflowing animation effects
              }}
            >
              {sameDivision ? (
                <div
                  style={{
                    animation: "shine 1.5s infinite", // Animation for the shining effect
                  }}
                >
                  ⭐ {item?.division?.name || ""}
                </div>
              ) : (
                <div>{item?.division?.name || ""}</div>
              )}
            </div>
          ),
        },
      ]}
    >
      <Grid container spacing={3} key={itemIndex} style={{ padding: "16px" }}>
        <Grid item xs={2}>
          <Label label={"SKU"} value={item?.product?.sku || "-"} />
        </Grid>

        <Grid item xs={2}>
          <Label
            label={"Gross >< Net Weight"}
            value={`${
              item?.product?.grossWeight
                ? formatWeight(item?.product?.grossWeight)
                : "-"
            } >< ${
              item?.product?.netWeight
                ? formatWeight(item?.product?.netWeight)
                : ""
            }`}
          />
        </Grid>

        <Grid item xs={2}>
          <Label
            label={"Diamond Weight"}
            value={
              item?.product?.totalDiamondWeight
                ? formatWeight(item?.product?.totalDiamondWeight, {
                    inputInCts: true,
                  })
                : "-"
            }
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Engraving"}
            value={`${item.product?.specificEngraving || "-"}`}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Category"}
            value={item?.product?.category?.name || "-"}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Purity"}
            value={
              item?.product?.purity?.value
                ? item?.product?.purity?.value + " KT"
                : ""
            }
          />
        </Grid>

        <Grid item xs={1}>
          <Label label={"Color"} value={item?.product?.color || "-"} />
        </Grid>

        <Grid item xs={1}>
          <Label label={"Size"} value={item?.product?.size || "-"} />
        </Grid>

        {/* <Grid item xs={1}>
          {uiConsoleObject ? (
            <Tooltip
              placement="top"
              PopperProps={{
                strategy: "fixed", // This will keep the Tooltip inside the viewport
              }}
              title={(() => {
                return <pre>{JSON.stringify(uiConsoleObject, null, 2)}</pre>;
              })()}
            >
              <TroubleshootIcon style={{ fontSize: "48px", color: "gold" }} />
            </Tooltip>
          ) : null}
        </Grid> */}

        <Grid
          item
          xs={2}
          style={{ cursor: "pointer" }}
          onClick={() =>
            setOpenImagePreview([
              {
                fileName: "Image.png",
                fileType: "image/png",
                location: stockImageLocation,
                signedUrl: stockImageLocation,
              },
            ])
          }
        >
          <LazyLoad>
            <img
              src={stockImageLocation || oopsNotFound}
              style={{ height: "10rem", width: "10rem", objectFit: "contain" }}
              onError={(event) => {
                event.target.src = imageExpired;
              }}
            />
          </LazyLoad>
        </Grid>

        {openImagePreview.length ? (
          <MyCarousel
            items={openImagePreview}
            openPreview={true}
            setOpenPreview={() => setOpenImagePreview([])}
          />
        ) : null}

        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Label
                label={"Ordered Priority"}
                value={
                  isCustomized
                    ? enums.orderPriority.makeToOrder
                    : item?.product?.stockStatus === enums.stockStatus.staged || // just because, now app shows all stockStatus products for stockWise clients and staged is only enough to check as other than Staged and In Invnetory, other stockStatus will also have lockedFrom.orderPro
                      Object.values(item?.product?.lockedFrom || {}).find(
                        (app) => app
                      )
                    ? enums.orderPriority.makeToOrder
                    : enums.orderPriority.ordered
                }
              />
            </Grid>

            <Grid item xs={1}>
              <Label label={"Quantity"} value={`${item.quantity}`} />
            </Grid>

            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              {isVendor ? null : (
                <Label
                  label={"Ordered Price"}
                  value={`${item.product?.jewelPrice || "-"}`}
                />
              )}
            </Grid>

            {isDelivered || isReturned || isRepair ? (
              <>
                <Grid item xs={2}>
                  {isVendor ? null : (
                    <Label
                      label={"Issued Price"}
                      value={`${item.issuedStocks.reduce(
                        (sum, stock) => sum + (stock.jewelPrice || 0),
                        0
                      )}`}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={2}></Grid>
            )}

            <Grid item xs={4}>
              <Label
                label={"Stock Available"}
                labelStyle={{ color: isAvailable ? "blue" : "red" }}
                valueStyle={{ color: isAvailable ? "blue" : "red" }}
                value={`${stockAvailable || "0"}`}
              />
              <a
                style={{ color: isAvailable ? "blue" : "red" }}
                target="_blank"
                href={`${commonConfig.hyperLinks?.["data-engine"]}#/${enums.models.products}/${item.product?.code}`}
              >
                View Original Product Details
              </a>
            </Grid>

            <Grid item xs={4}></Grid>

            <Grid item xs={2}>
              <Tooltip title="Ordered Price Breakup">
                <img
                  src={priceBreakUpIcon}
                  style={{ cursor: "pointer", height: "50px", width: "50px" }}
                  onClick={() => setOpenBreakup([item.product])} // sameDivision && put this in if you want to disable open price breakup for other division stocks which looks disabled
                />
              </Tooltip>
            </Grid>

            {isDelivered || isReturned || isRepair ? (
              <Grid item xs={2}>
                <Tooltip title="Issued Price Breakup">
                  <img
                    src={priceBreakUpIcon}
                    style={{
                      cursor: "pointer",
                      height: "50px",
                      width: "50px",
                      // backgroundColor: isDelivered
                      //   ? "green"
                      //   : isRepair
                      //   ? "orange"
                      //   : isReturned
                      //   ? "red"
                      //   : "black",
                    }}
                    onClick={() => setOpenBreakup(item.issuedStocks)}
                  />
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={2}>
          {isEditable && isAdmin ? (
            <RHAutoComplete
              options={vendorCodes}
              name={`items[${itemIndex}].vendorCode`}
              label={"Vendor Code"}
              value={item?.vendorCode || "-"}
              // style={{ backgroundColor: "white" }}
            />
          ) : (
            <Label label={"Vendor Code"} value={item?.vendorCode} />
          )}
        </Grid>

        <Grid item xs={2}>
          {isEditable ? (
            <RHAutoComplete
              label={"Order Status"}
              options={getWorkflowOptions()}
              name={`items[${itemIndex}].orderStatus`}
              value={item?.orderStatus || "-"}
              disableClearable
              // style={{ backgroundColor: "white" }}
            />
          ) : (
            <Label label={"Order Status"} value={item?.orderStatus} />
          )}
        </Grid>

        <Grid item xs={2}>
          {isEditable && isAdmin && isCustomized && isDelivered ? (
            <RHAsyncAutoComplete
              apiUrl={productSearchUrl}
              apiBody={{
                distinct: "styleNo",
                customerId,
                ...lockedFilterCriteria,
              }}
              name={`items[${itemIndex}].product`}
              label={"Design"}
              getOptionLabel={(opt) => opt?.styleNo}
              onBlur={() => {
                setValue(`items[${itemIndex}].issuedStocks`, []);
              }}
            />
          ) : (
            <Label label={"Design"} value={item?.product?.styleNo || "-"} />
          )}
        </Grid>

        {isDelivered || isReturned || isRepair ? (
          <>
            <Grid item xs={2}>
              {isEditable && isAdmin && isDelivered ? (
                <RHAsyncAutoComplete
                  name={`items[${itemIndex}].issuedStocks`}
                  label={"Issued Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  dynamicAPIbody={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  apiUrl={productSearchUrl}
                  apiBody={{
                    styleNo: item.product?.styleNo,
                    customerId,

                    ...{
                      ...lockedFilterCriteria,
                      $or: [
                        { stockStatus: enums.stockStatus.inventory },
                        {
                          "packagingHistory.assignee._id": customerId,
                          stockStatus: enums.stockStatus.approval,
                        },
                      ],
                    },
                  }}
                  onChange={(_, val) => {
                    let removeIndex = [],
                      removeRepairedIndex = [];

                    (val || []).map((prod) => {
                      (item.returnedStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeIndex.push(stockIndex);
                          return true;
                        }
                      });

                      (item.repairStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeRepairedIndex.push(stockIndex);
                          return true;
                        }
                      });
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].returnedStocks`,
                        (item.returnedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }

                    if (removeRepairedIndex.length) {
                      setValue(
                        `items[${itemIndex}].repairStocks`,
                        (item.repairStocks || []).filter((_, stockIndex) =>
                          removeRepairedIndex.includes(stockIndex)
                            ? false
                            : true
                        )
                      );
                    }

                    setValue(`items[${itemIndex}].issuedStocks`, val);
                  }}
                  // style={{ backgroundColor: "white" }}
                />
              ) : (
                <Label
                  label={"Issued Stocks"}
                  value={(item?.issuedStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>

            <Grid item xs={2}>
              {isEditable && isAdmin && isRepair ? (
                <RHAutoComplete
                  name={`items[${itemIndex}].repairStocks`}
                  label={"Repair Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  options={originalItem.issuedStocks || []}
                  onChange={(_, val) => {
                    let removeIndex = [];
                    (val || []).map((prod) => {
                      (item.issuedStocks || []).find(
                        (issuedStock, issuedStockIndex) => {
                          if (prod.sku === issuedStock.sku) {
                            removeIndex.push(issuedStockIndex);
                            return true;
                          }
                        }
                      );
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].issuedStocks`,
                        (item.issuedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }
                  }}
                />
              ) : (
                <Label
                  label={"Repair Stocks"}
                  value={(item?.repairStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {isEditable && isAdmin && isReturned ? (
                <RHAutoComplete
                  name={`items[${itemIndex}].returnedStocks`}
                  label={"Returned Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  options={issuedStocksOptions || []}
                  onChange={(_, val) => {
                    let removeIndex = [],
                      removeRepairedIndex = [];
                    (val || []).map((prod) => {
                      (item.issuedStocks || []).find(
                        (issuedStock, issuedStockIndex) => {
                          if (prod.sku === issuedStock.sku) {
                            removeIndex.push(issuedStockIndex);
                            return true;
                          }
                        }
                      );

                      (item.repairStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeRepairedIndex.push(stockIndex);
                          return true;
                        }
                      });
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].issuedStocks`,
                        (item.issuedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }

                    if (removeRepairedIndex.length) {
                      setValue(
                        `items[${itemIndex}].repairStocks`,
                        (item.repairStocks || []).filter((_, stockIndex) =>
                          removeRepairedIndex.includes(stockIndex)
                            ? false
                            : true
                        )
                      );
                    }
                  }}
                />
              ) : (
                <Label
                  label={"Returned Stocks"}
                  value={(item?.returnedStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>
          </>
        ) : null}
      </Grid>

      {/* Breakup </3 */}
      <Dialog
        open={openBreakup}
        onClose={() => setOpenBreakup(false)}
        maxWidth={"md"}
        fullWidth={true}
      >
        <MyDialogTitle onClose={setOpenBreakup}>Price Breakup</MyDialogTitle>
        <MyDialogContent>
          <Grid container spacing={2}>
            {(openBreakup || []).map((prod, prodIndex) => {
              return (
                prod && (
                  <Grid container style={{ padding: "24px" }} key={prodIndex}>
                    <Grid item xs={6}>
                      <Label
                        labelStyle={{ fontWeight: "bold" }}
                        valueStyle={{ fontWeight: "bold" }}
                        label={"SKU"}
                        value={prod.sku || "-"}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {isVendor ? null : (
                        <Label
                          label={"Price"}
                          value={prod.jewelPrice || "-"}
                          labelStyle={{ fontWeight: "bold" }}
                          valueStyle={{ fontWeight: "bold" }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Live Gold Rate"}
                          value={prod.jewelPriceBreakup?.liveGoldPrice || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Live Pure Metal Price"}
                          value={
                            prod.jewelPriceBreakup?.livePureMetalPrice || "-"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Gold"}
                          value={prod.jewelPriceBreakup?.gold || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Extras"}
                          value={
                            prod.jewelPriceBreakup?.totalExtrasPrice || "-"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Gold Labor"}
                          value={prod.jewelPriceBreakup?.goldLaborPrice || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"NetWeight * GoldLabor"}
                          value={
                            prod.jewelPriceBreakup?.netWeightGoldLaborPrice ||
                            "-"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Wastage"}
                          value={prod.jewelPriceBreakup?.appliedWastage || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {isVendor ? null : (
                        <Label
                          label={"Diamonds"}
                          value={
                            (prod.jewelPriceBreakup?.diamonds || [])
                              .map(
                                (d) => `💠. Price: ${d.price}, Rate: ${d.rate}`
                              )
                              ?.join("\n") || "-"
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                )
              );
            })}
          </Grid>
        </MyDialogContent>
      </Dialog>

      {/* Customization */}
      <Dialog
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <MyDialogTitle onClose={setOpenDetails}>Customizations</MyDialogTitle>
        <MyDialogContent>
          <Grid
            container
            spacing={3}
            key={itemIndex}
            style={{ padding: "16px" }}
          >
            <Grid
              item
              xs={12}
              container
              style={{ backgroundColor: "lightblack" }}
            >
              <Grid item xs={2}>
                <Label
                  label={"Category"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.category?.name || "-"}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Sub Category"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.subCategory?.name}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Collectin Line"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.collectionLine?.name}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Specific Engraving"}
                  value={item?.specificEngraving}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Melting"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.melting}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Screw"}
                  value={item?.screw}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Size"}
                  value={item?.size}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Color"}
                  value={item?.color}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Setting"}
                  value={item.setting}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Gross Weight (g)"}
                  value={
                    item.grossWeight
                      ? roundOff(item.grossWeight, { weight: true })
                      : "-"
                  }
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Purity"}
                  value={
                    item?.purity?.value
                      ? `${item.purity.value} KT - ${item.purity.percent} %`
                      : ""
                  }
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Metal Type"}
                  value={item.metalType}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={4}>
                <Label
                  label={"Remarks"}
                  value={item?.remarks}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
            </Grid>

            {item.diamondDetails?.length ? (
              <Grid
                item
                xs={12}
                container
                style={{
                  // backgroundColor: "lightblue",
                  padding: "16px",
                }}
              >
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Diamond Details
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      {diamondColumns.map((col, colIndex) => (
                        <TableCell key={colIndex}>{col.label}</TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {(item.diamondDetails || []).map(
                        (diamond, diamondIndex) => (
                          <TableRow key={diamondIndex}>
                            {diamondColumns.map((col, colIndex) => (
                              <TableCell key={colIndex}>
                                {colIndex === 0
                                  ? diamondIndex + 1
                                  : getNestedObjValue(diamond, col.field)}
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </MyDialogContent>
      </Dialog>
    </MainSection>
  );
};

const componentStyles = {
  greenLabel: { color: "black" },
};

export default DetailHeader;
